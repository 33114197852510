@import "assets/styles/mixins";
@import "assets/styles/variables";

.product_item {
  @include dflex(flex-start, stretch, column);

  width: 48%;
  line-height: 1.2;
  border-radius: $borderRadiusSmall;
  box-shadow: $boxShadowItem;
  background-color: $white;
  transition: $transition;
  overflow: hidden;

  &:nth-child(odd) {
    margin-bottom: 20px;
    margin-right: 4%;
  }

  &:nth-child(even) {
    margin-top: 20px;
  }

  .product_item__link {
    width: 100%;
    height: 100%;

    &:hover {
      .product_item__link_img img {
        top: -10%;
        left: -10%;
        width: 120%;
        height: 120%;
      }
    }
  }

  .product_item__link_img {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 45vw;
    overflow: hidden;

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      max-width: 120%;
      object-fit: cover;
      transition: $transition;
    }
  }

  .product_item__slider {
    display: block;
    width: 100%;
    padding: 0;
  }

  .product_item__link_description {
    display: block;
    padding: 5px;
  }

  .product_badges {
    margin-bottom: 5px;
  }

  .product_item__link_name {
    display: block;
    margin-bottom: 8px;
    font-size: $textMobileSize;
    color: $black;
  }

  .product_item__price {
    justify-content: center;
    font-size: min(4.2vw, 18px);
  }
}

/*  >  576 */
@include small-tablet {
  .product_item {
    .product_item__link_img {
      height: 35vw;
    }

    .product_item__link_name {
      font-size: $textNormalSize;
    }

    .product_item__price {
      font-size: 18px;
    }
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .product_item {
    width: 31.3%;
    margin: 0 1% 20px;

    &:nth-child(odd) {
      margin-bottom: 20px;
      margin-right: 1%;
    }

    &:nth-child(even) {
      margin-top: 0;
    }

    .product_item__link_img {
      height: min(25vw, 250px);
    }

    .product_badges {
      margin-bottom: 10px;
    }

    .product_item__link_name {
      margin-bottom: 15px;
      font-size: 18px;
    }

    .product_item__price {
      font-size: 22px;
    }
  }
}
/*  >  768 */

/*  >  992 */
@include desktop {
  .product_item {
    width: 23%;
    margin-bottom: 30px;

    &:nth-child(odd) {
      margin-bottom: 30px;
    }

    .product_item__link_img {
      height: min(15vw, 200px);
    }

    .product_item__price {
      font-size: 24px;
    }
  }
}
/*  >  992 */

/*  >  1100 */
@include large-display {
  .product_item {
    .product_item__link_img {
      height: 250px;
    }
  }
}
/*  >  1100 */
