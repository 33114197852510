@import "assets/styles/variables";
@import "assets/styles/mixins";

.table_item {
  padding: 3px;
  font-size: $textMobileSize;
  border: 1px solid $grey;

  span {
    font-weight: bold;
    display: inline-block;
  }
}

/*  >  768 */
@include tablet {
  .table_item {
    padding: 5px;
    font-size: $textNormalSize;
  }
}
/*  >  768 */
