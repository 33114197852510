@import "assets/styles/mixins";
@import "assets/styles/variables";

/* filters start */
.filters {
  @include dflex(center, stretch, row, wrap);

  margin-bottom: 20px;
  padding: 5px;
  line-height: 1;
  background-color: $lightGrey;

  .filter_button {
    position: relative;
    z-index: 1;
    width: fit-content;
    max-width: 31%;
    margin: 0 1.5vw;
    padding: 5px 20px 5px 5px;
    font-size: $textSmallSize;
    background-color: $withoutColor;

    &:after {
      @include psevdo(8px);

      right: 2px;
      width: 15px;
      height: 11px;
      background: url("../../assets/img/downPink.svg") no-repeat center center / contain;
      filter: brightness(0);
      transition: $transition;
    }

    &.active {
      color: $mainColor1;

      &:after {
        filter: none;
      }
    }

    &.arrowUp:after {
      transform: rotate(180deg);
    }
    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }
}
/* filters end */

/*  >  576 */
@include small-tablet {
  .filters {
    .filter_button {
      margin: 0 1%;
      font-size: $textNormalSize;

      &:after {
        top: 10px;
      }
    }
  }
}
/*  >  576 */

/*  >  992 */
@include desktop {
  .filters {
    margin-bottom: 40px;

    .filter_button {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}
/*  >  992 */
