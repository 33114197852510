@import "assets/styles/mixins";
@import "assets/styles/variables";

.cat_item {
  @include dflex(flex-start, stretch, column);

  width: 48%;
  margin-bottom: 20px;
  line-height: 1.2;
  transition: $transition;

  &:hover .cat_item__link {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);

    .cat_item__img img {
      top: -10%;
      left: -10%;
      width: 120%;
      height: 120%;
    }

    .cat_item__name {
      color: $mainColor1;
    }
  }

  span {
    display: block;
  }

  .cat_item__link {
    width: 100%;
    height: 100%;
    border-radius: $borderRadiusSmall;
    box-shadow: $boxShadowItem;
    text-align: center;
    overflow: hidden;
  }

  .cat_item__img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 40vw;
    overflow: hidden;

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      max-width: 120%;
      object-fit: cover;
      transition: $transition;
    }
  }

  .cat_item__name {
    display: block;
    padding: 2vw;
    font-size: min(3.4vw, 16px);
    color: $black;
    transition: color $transition;
  }
}

/*  >  576 */
@include small-tablet {
  .cat_item {
    width: 31%;
    margin: 0 1% 20px;

    .cat_item__img {
      height: 26vw;
    }

    .cat_item__name {
      padding: 10px 5px;
      font-size: $textNormalSize;
    }
  }
}
/*  >  576 */

/*  >  992 */
@include desktop {
  .cat_item {
    width: 23%;

    .cat_item__img {
      height: 20vw;
    }

    .cat_item__name {
      font-size: 18px;
    }
  }
}
/*  >  992 */

/*  >  1100 */
@include large-display {
  .cat_item {
    .cat_item__img {
      height: min(20vw, 250px);
    }
  }
}
/*  >  1100 */
