@import "assets/styles/mixins";
@import "assets/styles/variables";

.cart_item {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 20px 10px;
  border-radius: $borderRadius;
  box-shadow: $boxShadowItem;
  background-color: $white;

  & ~ .cart_item {
    margin-top: 20px;
  }

  .cart_item__remove {
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 1;
    width: 20px;
    height: 20px;
    padding: 0;
    text-align: center;
    font-size: 12px;
    vertical-align: middle;
    border-radius: $borderRadius;
    color: $white;
    background-color: $red;
  }

  .cart_item__link {
    @include dflex(flex-start, center, row);
  }

  .item_img {
    width: min(20vw, 70px);
    height: min(20vw, 70px);
    margin-right: 5px;
    border-radius: $borderRadiusSmall;
    object-fit: cover;
  }

  .item_name {
    flex-grow: 1;
    width: calc(100% - 20vw - 5px);
    font-weight: bold;
    font-size: min(4vw, 16px);
    color: $black;
  }

  .attributes {
    margin: 5px 0;
    font-size: $textMobileSize;

    .attributes__item {
      display: block;
    }

    .attributes__item_key {
      font-weight: bold;
    }
  }

  .item_count {
    @include dflex(flex-start, center, row);

    margin-top: 10px;
    font-size: min(5vw, 16px);
  }

  .item_count_value {
    margin: 0 5px;
  }

  .item__count_separator {
    margin: 0 5px;
  }
}



/*  >  576 */
@include small-tablet {
  .cart_item {
    .item_img {
      margin-right: 10px;
    }

    .item_name {
      width: calc(100% - 100px);
      font-size: 20px;
    }

    .attributes {
      margin: 15px 0;
      font-size: $textNormalSize;
    }

    .item_count {
      margin-top: 15px;
      font-size: $textNormalSize;
    }
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .cart_item {
    width: 100%;
    padding: 20px 10px;

    & ~ .cart_item {
      margin-top: 20px;
    }

    .item_name {
      font-size: 22px;
    }
  }
}
/*  >  768 */

/*  >  992 */
@include desktop {
  .cart_item {
    width: 100%;
    padding: 20px 10px;

    & ~ .cart_item {
      margin-top: 20px;
    }

    .cart_item__remove {
      top: 3px;
      right: 5px;
      z-index: 1;
      width: 20px;
      height: 20px;
    }
  }
}
/*  >  992 */
