@import "./assets/styles/variables";
@import "./assets/styles/settings";
@import "swiper/scss";

:root {
  --header-size: 0;
  --footer-size: 0;
  --top-section: 0;
}

.body-stop {
  overflow: hidden;
}

.text__bold {
  font-weight: bold;
}

.button {
  width: max-content;
  max-width: 100%;
  font-size: $textMobileSize;
  line-height: 1.2;
  text-align: center;
  border: $mainBorder;
  border-radius: $borderRadius;
  color: $white;
  background-color: $mainColor1;

  &:hover {
    color: $mainColor1;
    background-color: $white;
  }

  &:disabled {
    opacity: 0.8;
  }
}

.hidden_element {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 1px;
  height: 1px;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
}

.form {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  label {
    display: block;
  }

  span {
    display: block;
    background-color: $withoutColor;
  }

  select,
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  textarea {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid $white;
    border-radius: 15px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.411);
    color: $black;
    background-color: $white;
  }

  select {
    cursor: pointer;
  }

  textarea {
    height: 70px;
    min-height: 60px;
    max-height: 300px;
    resize: vertical;
  }

  .form__submit {
    display: block;
    width: 100%;
    margin: 20px 0 5px;
    padding: 10px;
    border: 2px solid $mainColor1;
    border-radius: $borderRadius;
    color: $white;
    background-color: $mainColor1;

    &:not(:disabled):hover {
      color: $mainColor1;
      background-color: $white;
    }

    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }

  .form__approved {
    margin-top: 5px;
    font-size: 12px;
    text-align: center;
  }
}

.page-section {
  padding: $pFullHeadPageMob;
  overflow: hidden;
}

.modal {
  @include dflex;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 1px;
  height: 1px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.4s;
  overflow: hidden;

  .modal__wrap {
    position: relative;
    z-index: 1;
    width: 96%;
    max-width: 700px;
    margin: auto;
    padding: 30px;
    background-color: $white;

    .close-modal {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 30px;
      height: 30px;
      padding: 0;
      font-size: 30px;
      line-height: 30px;
      border: none;
      border-radius: 5px;
      color: $white;
      background-color: $red;
    }
  }

  .modal__wrap_title {
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 20px;
    font-size: 30px;
    text-align: center;
  }

  .modal__wrap_text {
    font-size: 16px;
  }

  .cdek-map {
    margin-top: 20px;
    width: 100%;
    height: 400px;
  }
}

.active-modal {
  z-index: 10000;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.text-content {
  color: $grey;
  margin: 20px auto;

  h2,
  h3,
  h4,
  h5,
  .text-content__title {
    margin: 15px auto;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
  }

  p {
    font-size: $textMobileSize;

    & ~ p {
      margin-top: 8px;
    }
  }

  a {
    display: inline;
    color: $orange;

    &:hover {
      color: $blue;
    }
  }

  ul {
    margin: 10px auto;
    font-size: $textMobileSize;

    li {
      position: relative;
      z-index: 1;
      text-indent: 15px;

      &:before {
        @include psevdo(0);

        left: 0;
        width: 15px;
        height: 15px;
        background: url(./assets/img/check.png) no-repeat center / contain;
      }

      & ~ li {
        margin-top: 5px;
      }
    }
  }

  .text-content__photo {
    width: 100%;
    height: 60vw;
    margin: 10px 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text-content__gallery {
    .text-content__photo {
      width: 100%;
    }
  }
}

.category {
  padding: $pCategoryPageMob;
}

/*  >    575    */
@include small-tablet {
  .page-section {
    padding: $pFullHeadPageSmallTablet;
  }

  .button {
    font-size: $textNormalSize;
  }

  .form {
    .form__submit {
      font-size: 18px;
    }
  }

  .text-content {
    h2,
    h3,
    h4,
    h5,
    .text-content__title {
      font-size: 18px;
    }

    p {
      font-size: $textNormalSize;
    }

    ul {
      font-size: $textNormalSize;

      li {
        &:before {
          top: 2px;
        }
      }
    }

    .text-content__photo {
      height: 50vw;
    }

    .text-content__gallery {
      @include dflex(flex-start, stretch, row, wrap);

      .text-content__photo {
        width: 48%;
        height: 40vw;
        margin: 10px 1%;
      }
    }
  }

  .category {
    padding: $pCategoryPageSmallTablet;
  }
}
/*  >    575    */

/*     > 768    */
@include tablet {
  .page-section {
    padding: $pFullHeadPageTablet;
  }

  .form {
    select,
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    textarea {
      padding: 15px 20px;
      font-size: 16px;
      border-radius: $borderRadius;
    }

    textarea {
      height: 90px;
    }

    .form__submit {
      font-size: 20px;
    }
  }

  .text-content {
    h2,
    h3,
    h4,
    h5,
    .text-content__title {
      margin: 20px auto;
      font-size: 22px;
    }

    p {
      & ~ p {
        margin-top: 10px;
      }
    }

    ul {
      li {
        &:before {
          left: 0;
        }

        & ~ li {
          margin-top: 8px;
        }
      }
    }
  }

  .category {
    padding: $pCategoryPageTablet;
  }
}
/*     > 768    */

/*  >  992 */
@include desktop {
  .text-content {
    h2,
    h3,
    h4,
    h5,
    .text-content__title {
      font-size: 26px;
    }

    .text-content__photo {
      margin: 20px 0;
      height: min(50vw, 450px);
    }

    .text-content__gallery {
      @include dflex(flex-start, stretch, row, wrap);

      margin: 20px 0;

      .text-content__photo {
        width: 31%;
        height: min(40vw, 320px);
      }
    }
  }
}
/*  >  992 */
