@import "assets/styles/mixins";
@import "assets/styles/variables";

.wrapper {
  width: calc(100% - 20px);
  max-width: 1300px;
  margin: 0 auto;
}

/*  >  576 */
@include small-tablet {
  .wrapper {
    width: calc(100% - 40px);
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .wrapper {
    width: calc(100% - 80px);
  }
}
/*  >  768 */

/*  >  1100 */
@include large-display {
  .wrapper {
    width: calc(100% - 100px);
  }
}
/*  >  1100 */
