@import "assets/styles/variables";

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    position: relative;
    display: block;
    width: 80px;
    height: 80px;
    margin: auto;

    div {
        position: absolute;
        display: block;
        width: 80%;
        height: 80%;
        margin: 8px;
        border-radius: 50%;
        border: 8px solid $mainColor1;
        border-color: $mainColor1 transparent transparent transparent;
        box-sizing: border-box;
        animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
    div:nth-child(1) {
        animation-delay: -0.45s;
    }
    div:nth-child(2) {
        animation-delay: -0.3s;
    }
    div:nth-child(3) {
        animation-delay: -0.15s;
    }
}
