@import "assets/styles/mixins";
@import "assets/styles/variables";

.testimonials {
  .testimonials__item {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: $borderRadius;
    background-color: $white;
  }

  .testimonials__item_name {
    font-size: $textNormalSize;
  }

  .testimonials__item_content {
    margin-top: 10px;
    font-size: $textMobileSize;
    color: $grey;
  }
}

/*  >  576 */
@include small-tablet {
  .testimonials {
    .testimonials__item {
      margin-bottom: 30px;
      padding: 20px;
    }

    .testimonials__item_name {
      font-size: 20px;
    }
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .testimonials {
    max-width: 750px;
    margin: 0 auto;

    .testimonials__item {
      margin-bottom: 40px;
      padding: 30px;
    }

    .testimonials__item_name {
      font-size: 22px;
    }

    .testimonials__item_content {
      margin-top: 20px;
      font-size: $textNormalSize;
    }
  }
}
/*  >  768 */
