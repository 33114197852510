@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    src: url("#{$file-path}.woff2") format("woff2"), url("#{$file-path}.woff") format("woff"),
      url("#{$file-path}.otf") format("opentype"), url("#{$file-path}.ttf") format("truetype");
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin dflex($ja: center, $ai: center, $fd: row, $fw: nowrap) {
  display: flex;
  flex-flow: $fd $fw;
  align-items: $ai;
  justify-content: $ja;
}

@mixin psevdo($top: 0, $zIndex: -1, $content: "", $pos: absolute) {
  content: $content;
  position: $pos;
  top: $top;
  z-index: $zIndex;
}

@mixin small-tablet() {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin large-display() {
  @media screen and (min-width: 1100px) {
    @content;
  }
}


