@import "assets/styles/mixins";
@import "assets/styles/variables";

.swiper-wrapper {
  overflow: hidden;
}

.swiper-button-next,
.swiper-button-prev {
  display: none;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination {
  bottom: 0;

  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    opacity: 0.6;
    background-color: $black;

    &.swiper-pagination-bullet-active {
      opacity: 1;
      background-color: $mainColor1;
    }
  }
}

/*    > 576    */
@include small-tablet() {
  .swiper-button-next,
  .swiper-button-prev {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $mainColor1;
    overflow: hidden;

    &:after {
      @include psevdo(0, 1);

      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../../assets/img/down.svg") no-repeat center / 70%;
    }
  }

  .swiper-button-next:after {
    transform: rotate(-90deg);
  }

  .swiper-button-prev:after {
    transform: rotate(90deg);
  }
}
/*    > 576    */
