@import "assets/styles/mixins";
@import "assets/styles/variables";

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: 5px 0;
  color: $black;
  background-color: $white;

  .wrapper {
    line-height: 1;
  }

  .nav {
    width: 100%;
    text-align: center;
  }

  .nav__list {
    @include dflex(center, stretch, row, nowrap);

    &_item {
      width: fit-content;
      max-width: 23%;
      margin: 0 1%;
    }

    .anchor {
      position: relative;
      z-index: 1;
      height: 100%;
      padding: 5px;
      font-size: min(3.2vw, 12px);
      text-transform: uppercase;
      color: $black;
      border-radius: $borderRadius;

      span {
        display: block;
        width: 100%;
        height: min(8vw, 30px);
        margin-bottom: 1.6vw;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
}

/*  >  992 */
@include desktop {
  .footer {
    .nav__list {
      .anchor {
        font-size: $textSmallSize;
      }
    }
  }
}
/*  >  992 */
