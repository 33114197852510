/* common colors start */
$withoutColor: transparent;
$white: #fff;
$black: #181619;
$orange: #ff811c;
$red: #f70401;
$green: #00b436;
$grey: #7a7a7a;
$lightGrey: #ebebeb;
$mainColor1: #ff811c;
$blue: #331cff;

$badgeGift: #fdcba4;
$badgeDelivery: #b6fdff;
$badgeNew: #fe828e;
$badgeHot: #a8fe82;

$mainBorder: 2px solid $mainColor1;
$boxShadowItem: 0 2px 5px transparentize($black, 0.9);
/* common colors end */

$bodyColor: $black;
$bodyBGColor: $lightGrey;
$scrollBarBg: $black;
$scrollBarThumb: $orange;

/* common variables start */
$transition: 0.3s;
$borderRadius: 20px;
$borderRadiusSmall: 5px;
$textSmallSize: 14px;
$textNormalSize: 16px;

// fonts  > 320
$textMobileSize: min(3.5vw, 14px);
$pageTitleMobile: 22px;
$pHomePageMobile: 65px 0 80px;
$pFullHeadPageMob: 80px 0;
$pShopPageMob: 60px 0 80px;
$pErrorPageMob: 30vw 0 80px;
$pCategoryPageMob: 42px 0 80px;
$pProductPageMob: 50px 0 80px;

// fonts  > 576
$pageTitleSmallTablet: 34px;
$pHomePageSmallTablet: 95px 0 105px;
$pFullHeadPageSmallTablet: 110px 0 105px;
$pShopPageSmallTablet: 75px 0 105px;
$pErrorPageSmallTablet: 180px 0 105px;
$pCategoryPageSmallTablet: 54px 0 105px;
$pProductPageSmallTablet: 70px 0 105px;

// fonts  > 768
$pageTitleTablet: 38px;
$pHomePageTablet: 100px 0 115px;
$pFullHeadPageTablet: 120px 0 115px;
$pShopPageTablet: 90px 0 115px;
$pErrorPageTablet: 180px 0 115px;
$pCategoryPageTablet: 63px 0 115px;
$pProductPageTablet: 80px 0 115px;

// fonts  > 992
$pageTitleDesktop: 40px;

/* common variables end */
