@import "assets/styles/mixins";

.pagination_list {
  @include dflex(center, stretch, row, wrap);

  flex-wrap: wrap;
  margin: 20px auto 0;
}

/*  >  1100 */
@include large-display {
  .pagination_list {
    margin-top: 35px;
  }
}
/*  >  1100 */
