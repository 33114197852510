@import "assets/styles/mixins";
@import "assets/styles/variables";

.top {
  padding: $pHomePageMobile;
  overflow: hidden;

  .nav_home {
    width: 100%;
    margin-bottom: 5px;
    text-align: center;
  }

  .nav_home__list {
    @include dflex(center, stretch, row, wrap);

    &_item {
      width: fit-content;
      max-width: 28%;
      margin: 0 8px 5px;
    }

    .anchor {
      position: relative;
      z-index: 1;
      height: 100%;
      font-size: min(3vw, 14px);
      text-transform: uppercase;
      color: $grey;

      &:hover {
        color: $mainColor1;
      }

      span {
        display: block;
        width: 100%;
        height: min(12vw, 45px);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  .top__title {
    display: none;
  }

  .content_wrapper {
    @include dflex(space-between, flex-start, row, wrap);
  }

  .main_slider {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 28px;

    & > ul {
      border-radius: $borderRadiusSmall;
    }
  }

  .slider_item_link {
    width: 100%;

    .slider_item_img {
      width: 100%;
      height: min(45vw, 220px);
      object-fit: cover;
    }
  }
}

/*  >  576 */
@include small-tablet {
  .top {
    padding: $pHomePageSmallTablet;

    .nav_home {
      margin-bottom: 25px;
    }

    .nav_home__list {
      .anchor {
        span {
          height: 50px;
        }
      }
    }

    .slider_item_link {
      width: 100%;

      .slider_item_img {
        height: min(40vw, 300px);
      }
    }
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .top {
    padding: $pHomePageTablet;

    .nav_home__list_item {
      margin: 0 15px 5px;
    }

    .main_slider {
      width: 600px;
      margin: 0 auto 40px;
      padding-bottom: 28px;

      & > ul {
        border-radius: $borderRadiusSmall;
      }
    }


    .slider_item_link {
      .slider_item_img {
        height: min(40vw, 350px);
        min-height: auto;
      }
    }
  }
}
/*  >  768 */
