@import "assets/styles/mixins";
@import "assets/styles/variables";

.error_page {
  padding: $pErrorPageMob;
  text-align: center;
}

.error_text {
  font-size: 16px;
}

/*  >  576 */
@include small-tablet {
  .error_page {
    padding: $pErrorPageSmallTablet;
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .error_page {
    padding: $pErrorPageTablet;
  }
}
/*  >  768 */

/*  >  992 */
@include desktop {
  .error_title {
    margin-bottom: 25px;
    font-size: 200px;
  }
}
/*  >  992 */
