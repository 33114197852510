@import "assets/styles/variables";
@import "assets/styles/mixins";

.pagination_item {
  margin: 5px;
  font-weight: bold;
  font-size: min(3vw, 14px);
  line-height: 1;
  text-align: center;
}

.pagination_item__current {
  position: relative;
  z-index: 1;
  height: 100%;
  font-size: inherit;

  .pagination_item__current_number {
    width: 55px;
    height: 100%;
    display: block;
    padding: 5px;
    font-size: inherit;
    border-radius: $borderRadius;
    border: 2px solid $mainColor1;
    text-align: center;
    color: $white;
    background-color: $mainColor1;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .pagination_item__current_submit {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    width: 1px;
    height: 1px;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}

.pagination_item__button {
  min-width: 30px;
  padding: 5px;
  border-radius: $borderRadius;
  border: 2px solid $grey;
  font-size: min(3vw, 14px);
  color: $white;
  background-color: $grey;
}

/*  >  576 */
@include small-tablet {
  .pagination_item {
    font-size: 16px;
  }

  .pagination_item__button {
    min-width: 35px;
    font-size: 16px;
  }
}
/*  >  576 */
