.form__row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  & ~ .form__row {
    margin-top: 20px;
  }
}
