@import "assets/styles/mixins";
@import "assets/styles/variables";

.cart_products {
  width: 100%;
  margin: 20px auto;
}

.not_products {
  font-size: min(5vw, 16px);
  text-align: center;
}

.cart_amount {
  margin-top: 35px;

  .small {
    font-size: 14px;
    color: $grey;
  }

  .big {
    font-weight: bold;
    font-size: 18px;
  }
}

/*  >  576 */
@include small-tablet {
  .cart_amount {
    .small {
      font-size: 16px;
    }

    .big {
      font-size: 24px;
    }
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .cart_products {
    margin: 30px auto;
  }

  .cart_amount {
    margin-top: 35px;
  }
}
/*  >  768 */

/*  >  992 */
@include desktop {
  .cart_products {
    margin: 40px auto;
  }
}
/*  >  992 */
