@import "assets/styles/mixins";
@import "assets/styles/variables";

.badge {
  width: fit-content;
  max-width: 100%;
  flex-grow: 1;
  display: block;
  margin: 2px;
  padding: 1vw 2vw;
  font-size: min(2.5vw, 12px);
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;
  color: $grey;

  &.delivery {
    background-color: $badgeDelivery;
  }

  &.gift {
    background-color: $badgeGift;
    color: $black;
  }

  &.hot {
    background-color: $badgeHot;
  }

  &.newProduct {
    background-color: $badgeNew;
    color: $white;
  }
}

/*  >  576 */
@include small-tablet {
  .badge {
    padding: 5px;
  }
}
/*  >  576 */

/*  >  992 */
@include desktop {
  .badge {
    max-width: 300px;
  }
}
/*  >  992 */