@import "assets/styles/variables";
@import "assets/styles/mixins";

form {
  .error {
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    select {
      border-color: $red;
    }
  }

  .input__title {
    padding-left: 5px;
    font-size: 16px;
  }

  .error__text {
    display: block;
    width: 100%;
    font-size: 12px;
    color: $red;
  }

  .input__wrapper {
    width: 100%;

    & ~ .input__wrapper {
      margin-top: 10px;
    }
  }

  label.required {
    display: block;

    .input__title {
      position: relative;
      z-index: 1;

      &:after {
        content: "*";
        color: $red;
      }
    }
  }
}

/*  >  576 */
@include small-tablet {
  form {
    .input__title {
      padding-left: 10px;
    }

    .error__text {
      font-size: 14px;
    }

    .input__wrapper ~ .input__wrapper {
      margin-top: 15px;
    }
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  form {
    .input__title {
      font-size: 18px;
    }

    .input__wrapper ~ .input__wrapper {
      margin-top: 20px;
    }
  }
}
/*  >  768 */
