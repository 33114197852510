@import "assets/styles/mixins";

.catalog__list {
  @include dflex(space-between, stretch, row, wrap);

  margin: 0 auto;
}

/*  >  576 */
@include small-tablet {
  .catalog__list {
    justify-content: flex-start;
  }
}
/*  >  576 */
