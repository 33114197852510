@import "assets/styles/mixins";
@import "assets/styles/variables";

.error_page {
  padding: $pErrorPageMob;
  text-align: center;
}

.error_title {
  margin-bottom: 15px;
  font-size: 85px;
  line-height: 1;
  color: $mainColor1;
}

.error_text {
  font-size: 16px;
}

/*  >  576 */
@include small-tablet {
  .error_page {
    padding: $pErrorPageSmallTablet;
  }

  .error_title {
    font-size: 120px;
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .error_page {
    padding: $pErrorPageTablet;
  }

  .error_title {
    font-size: 160px;
  }
}
/*  >  768 */

/*  >  992 */
@include desktop {
  .error_title {
    margin-bottom: 25px;
    font-size: 200px;
  }

  .error_text {
    font-size: 18px;
  }
}
/*  >  992 */
