@import "assets/styles/mixins";
@import "assets/styles/variables";

/* shop start */
.shop {
  padding: $pShopPageMob;

  .title {
    display: none;
  }
}
/* shop end */

/*  >  576 */
@include small-tablet {
  .shop {
    padding: $pShopPageSmallTablet;
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .shop {
    padding: $pShopPageTablet;
  }
}
/*  >  768 */
