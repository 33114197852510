@import "assets/styles/mixins";
@import "assets/styles/variables";

.search {
  @include dflex(center, stretch, row);

  flex-grow: 1;
  width: 70%;
  color: $black;
  position: relative;
  z-index: 1;

  .search_input {
    flex-grow: 1;
    width: 100%;
    padding: 5px 55px 5px 10px;
    border: $mainBorder;
    border-right: none;
    font-size: $textMobileSize;
    border-radius: $borderRadius;
  }

  .search_submit_wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    flex-shrink: 0;
    width: 50px;
    height: 100%;
    border: $mainBorder;
    border-radius: 0 $borderRadius $borderRadius 0;
    color: $white;
    background-color: $mainColor1;
    overflow: hidden;
    transition: $transition;

    &:before {
      @include psevdo(0, 1);

      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../assets/img/search.svg") no-repeat center center / contain;
      filter: brightness(0) invert(1);
      transition: $transition;
    }

    &:hover {
      color: $mainColor1;
      background-color: $white;

      &:before {
        filter: none;
      }
    }
  }
}

/*  >  576 */
@include small-tablet {
  .search {
    .search_input {
      font-size: $textNormalSize;
      padding-right: calc(20% + 10px);
    }

    .search_submit_wrapper {
      width: 20%;
    }
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .search {
    .search_input {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
    }
  }
}
/*  >  768 */
