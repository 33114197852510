@import "assets/styles/mixins";
@import "assets/styles/variables";

.item__count_values {
  @include dflex(flex-start, center, row);

  .item__count_minus,
  .item__count_plus {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 5px $black;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .item__count_value {
    min-width: 35px;
    margin: 0 10px;
    font-weight: bold;
    font-size: $textMobileSize;
    text-align: center;
  }
}

button.product__button {
  display: block;
  padding: 6px 20px;
  font-size: $textMobileSize;
}

/*  >  576 */
@include small-tablet {
  .item__count_values {
    .item__count_minus,
    .item__count_plus {
      width: 40px;
      height: 40px;
      font-size: 20px;
    }

    .item__count_value {
      min-width: 45px;
      font-size: 18px;
    }
  }

  button.product__button {
    margin: 25px 0;
    padding: 10px 30px;
    font-size: 16px;
  }
}
/*  >  576 */
