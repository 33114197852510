@import "assets/styles/mixins";
@import "assets/styles/variables";

.product_price {
  @include dflex(flex-start, center, row, wrap);

  line-height: 1;

  .price {
    font-weight: bold;
    color: $mainColor1;
  }

  .old__price {
    margin-left: 5px;
    text-decoration: line-through;
    color: $black;
  }

  .currency,
  .price_text {
    font-weight: normal;
    font-size: 75%;
    color: $black;
  }

  .separator {
    margin: 0 3px;
  }

  .currency {
    margin-left: 2px;
  }
}
