@import "assets/styles/mixins";
@import "assets/styles/variables";

.form_question {
  margin-top: 20px;
}

.table__wrapper {
  max-width: 700px;
  margin: 0 auto 40px;
  overflow-x: auto;
}

.table {
  .price {
    justify-content: center;
  }

  .table_bold_line {
    font-weight: bold;
  }
}

.deliveryContent {
  width: 100%;
  max-width: 500px;
  margin: 20px auto;

  .deliveryContent__title {
    margin-bottom: 6px;
    font-weight: 600;
    text-align: center;
  }

  .deliveryContent__choose_subtitle {
    margin-bottom: 6px;
    text-align: center;
  }

  .deliveryContent__choose {
    .deliveryContent__choose_title {
      padding-left: 10px;
      font-size: 14px;
    }

    select {
      width: 100%;
      margin-bottom: 10px;
      padding: 10px;
      font-size: 14px;
      border: 1px solid #fff;
      border-radius: 15px;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.411);
    }
  }

  .deliveryContent__info {
    text-align: left;
  }
}

.form__error {
  margin: 10px 0;
  font-size: 14px;
  text-align: center;
  color: $red;
}


// .modalVariant {
//   z-index: 1000;
// }

// .deliveryContentCDEK {
//   max-width: 700px;
//   margin: 10px auto;

//   .deliveryContentCDEK__title {
//     margin-bottom: 5px;
//     font-size: 18px;
//     text-align: center;
//   }

//   .deliveryContentCDEK__address {
//     margin-bottom: 5px;

//     span {
//       font-weight: bold;
//     }
//   }

//   .deliveryContentCDEK__error {
//     text-align: center;
//     color: $red;
//   }
// }