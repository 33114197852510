@import "assets/styles/mixins";
@import "assets/styles/variables";

.title {
  margin-bottom: 20px;
  font-weight: normal;
  font-size: $pageTitleMobile;
  line-height: 1.2;
  text-align: center;
  color: $grey;
}

.mt {
  margin-top: 20px;
}

/*  >  576 */
@include small-tablet {
  .title {
    font-size: $pageTitleSmallTablet;
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .title {
    margin-bottom: 35px;
    font-size: $pageTitleTablet;
  }

  .mt {
    margin-top: 35px;
  }
}
/*  >  768 */

/*  >  992 */
@include desktop {
  .title {
    font-size: $pageTitleDesktop;
  }
}
/*  >  992 */
