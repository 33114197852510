@import "assets/styles/mixins";
@import "assets/styles/variables";

/* product start */
.product {
  padding: $pProductPageMob;

  .product__title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: min(5.5vw, 22px);
    line-height: 1.2;
    text-align: center;
    color: $black;
  }

  .product__slider {
    width: 100%;
    padding-bottom: 30px;

    ul {
      padding: 0;
      margin: 0;
    }

    .swiper-pagination {
      bottom: 0;
    }
  }

  .product__photo {
    width: 100%;
    height: 75vw;
    padding: 0;
    background-color: $white;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .product__badges {
    margin-top: 20px;

    .badge__item {
      padding: 5px;
      font-size: min(3.2vw, 12px);
    }
  }

  .variations {
    display: flex;
    margin: 20px 0;
    padding: 5px;
    overflow-x: auto;

    .variations_button {
      flex-shrink: 0;
      padding: 0;
      margin-bottom: 5px;
      text-align: center;
      border: 1px solid $grey;
      border-radius: $borderRadiusSmall;
      background-color: $withoutColor;
      overflow: hidden;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.active {
        color: $white;
        border-color: $green;
        box-shadow: 0 0 10px $green;
        background-color: $black;
      }

      span {
        display: block;
      }

      .variations_button_text {
        padding: 3px;
        width: fit-content;
        min-width: 20px;
        font-size: min(3vw, 12px);
      }

      .variations_button_img {
        width: 50px;
        height: 35px;
        object-fit: cover;
      }
    }
  }

  .product__sale_info {
    @include dflex(flex-start, center, row);

    margin: 20px 0;
  }

  .product_variable_price,
  .product__price {
    width: 50%;
    font-size: min(7vw, 20px);
  }

  .product_variable_price {
    line-height: 1;
    color: $mainColor1;
  }

  .not_chosen_product_text {
    font-size: $textMobileSize;
  }

  .product__sale_info_item {
    width: 50%;
    color: $grey;
    font-weight: bold;
    font-size: $textMobileSize;
  }

  .tabs {
    margin-top: 25px;
    color: $grey;

    .tabs__buttons {
      @include dflex(flex-start, stretch, row, wrap);

      margin-bottom: 15px;
    }

    .tabs__buttons_item {
      padding: 5px 10px;
      margin-right: 1px;
      font-weight: bold;
      font-size: $textMobileSize;
      border: 1px solid transparentize($grey, 0.6);
      color: $grey;
      background-color: $white;

      &.active {
        color: $white;
        background-color: $mainColor1;
      }
    }

    .tabs__content {
      font-size: $textMobileSize;

      p ~ p {
        margin-top: 5px;
      }
    }
  }

  .attribute_item {
    font-size: $textMobileSize;

    .attribute_title {
      font-weight: bold;
    }
  }
}
/* product end */

/*  >  576 */
@include small-tablet {
  .product {
    padding: $pProductPageSmallTablet;

    .product__title {
      margin-bottom: 25px;
      font-size: 28px;
    }

    .product__photo {
      height: 50vw;
    }

    .product__badges {
      .badge__item {
        font-size: $textSmallSize;
      }
    }

    .variations {
      .variations_button {
        .variations_button_text {
          padding: 5px;
          font-size: $textSmallSize;
        }

        .variations_button_img {
          width: 70px;
          height: 45px;
        }
      }
    }

    .product_variable_price,
    .product__price {
      font-size: 26px;
    }

    .not_chosen_product_text {
      font-size: $textNormalSize;
    }

    .product__sale_info_item {
      font-size: $textSmallSize;
    }

    .tabs {
      .tabs__buttons_item {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: $textSmallSize;
      }

      .tabs__content {
        font-size: $textNormalSize;
      }
    }
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .product {
    padding: $pProductPageTablet;

    .product__title {
      margin-bottom: 30px;
      font-size: 34px;
    }

    .product__slider {
      width: 60%;
      margin: 0 auto;

      .product__photo {
        width: 100%;
      }
    }

    .product__photo {
      width: 60%;
      height: min(450px, 35vw);
      margin: 0 auto;
    }

    .product__badges {
      margin-top: 30px;

      .badge__item {
        padding: 10px;
        font-size: 16px;
      }
    }

    .product__sale_info {
      margin: 30px 0;
    }

    .product_variable_price,
    .product__price {
      font-size: 35px;
    }
  }
}
/*  >  768 */

/*  >  992 */
@include desktop {
  .product {
    .product__title {
      font-size: 36px;
    }
  }
}
/*  >  992 */
