@import "assets/styles/mixins";
@import "assets/styles/variables";

.cart {
  min-height: 100vh;
  padding: $pFullHeadPageMob;
  overflow: hidden;

  .order_delivery {
    margin-top: 20px;
    padding: 5px 30px;
    font-size: min(5vw, 14px);
  }
}

/*  >  576 */
@include small-tablet {
  .cart {
    padding: $pFullHeadPageSmallTablet;

    .order_delivery {
      margin-top: 20px;
      font-size: $textNormalSize;
    }
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .cart {
    padding: $pFullHeadPageTablet;



    .order_delivery {
      font-size: 18px;
    }
  }
}
/*  >  768 */
