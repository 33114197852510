@import "assets/styles/mixins";
@import "assets/styles/variables";

@keyframes animationPage {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.main {
  position: relative;
  z-index: 1;
  overflow-y: hidden;
}

.pageEnter,
.pageExit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100vh;
}

// exit
.pageExit {
  z-index: 0;
  height: 100vh;
  overflow: hidden;
  background-color: $lightGrey;
}

.pageExitActive {
  animation: hide 0.1s linear 0.6s;

  & > section {
    transform: translateY(var(--top-section));
  }
}

// enter
.pageEnter {
  z-index: -1;
  height: 100vh;
  overflow: hidden;
  background-color: $lightGrey;
  transform: translateX(0);
  box-shadow: 0 0 5px $grey;
}

.pageEnterActive {
  z-index: 10;
  animation: animationPage 0.7s linear;
}

// animations ended
.pageEnterDone {
  position: relative;
  z-index: 10;
  max-height: initial;
  background-color: $lightGrey;
  transform: translateX(0);
}
