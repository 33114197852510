@import "./normalize";
@import "./mixins";
@import "./variables";
@include font-face("Verdana", "../fonts/Verdana");
@include font-face("Verdana", "../fonts/Verdana-Italic", 400, italic);
@include font-face("Verdana", "../fonts/Verdana-Bold", bold);
@include font-face("Verdana", "../fonts/Verdana-BoldItalic", bold, italic);

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font: 16px / 1.4 Verdana, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  color: $bodyColor;
  background-color: $bodyBGColor;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1 0 auto;
  background-color: $bodyBGColor;
  overflow-x: hidden;
}

section {
  width: 100%;
}


h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
figure {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  display: block;
  text-decoration: none;
  transition: $transition;
}

img {
  display: block;
  max-width: 100%;
}

::-webkit-scrollbar {
  height: 5px;
  width: 15px;
  background-color: $scrollBarBg;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $scrollBarThumb;
}
