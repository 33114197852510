@import "assets/styles/mixins";
@import "assets/styles/variables";

/* header start */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: 5px 0;
  color: $black;
  // background-color: $white;
  background-color: $lightGrey;

  .wrapper {
    @include dflex(center, stretch, row, wrap);

    line-height: 1;
  }

  .nav_top {
    width: 100%;
    text-align: center;
  }

  .nav_top_list {
    @include dflex(center, stretch, row, wrap);

    &_item {
      margin: 0 5px 5px;
    }

    .anchor {
      position: relative;
      padding: 3px;
      font-size: $textMobileSize;
      color: $grey;

      &:hover {
        color: $mainColor1;
      }
    }
  }

  .header_down {
    @include dflex(center, center, row, wrap);

    width: 100%;
  }

  .direction_wrapper {
    @include dflex(flex-start, center, row, wrap);

    padding: 1px 6px;
    border-radius: $borderRadius;
    flex-shrink: 0;

    .back_link:before {
      background-image: url("../../assets/img/downPink.svg");
      transform: rotate(90deg);
    }

    .home_link:before {
      background-image: url("../../assets/img/shop.png");
    }

    .back_link,
    .home_link {
      position: relative;
      z-index: 1;
      display: block;
      width: min(8.4vw, 30px);
      height: min(8.4vw, 30px);
      padding: 0;
      background-color: $withoutColor;

      &:before {
        @include psevdo(0, 1);

        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
      }
    }

    .line {
      margin-right: 2vw;

      &:after {
        @include psevdo(15%, 1);

        right: -1vw;
        width: 2px;
        height: 70%;
        background-color: $mainColor1;
        pointer-events: none;
      }
    }
  }
}
/* header end */

/*  >  576 */
@include small-tablet {
  .header {
    padding: 10px 0;

    .nav_top {
      margin-bottom: 10px;
    }

    .nav_top_list {
      .anchor {
        padding: 3px 15px;
        font-size: $textNormalSize;
      }
    }

    .direction_wrapper {
      .back_link,
      .home_link {
        width: 32px;
        height: 32px;
      }

      .line {
        margin-right: 20px;

        &:after {
          right: -10px;
        }
      }
    }
  }
}
/*  >  576 */

/*  >  768 */
@include tablet {
  .header {
    .direction_wrapper {
      .back_link,
      .home_link {
        width: 40px;
        height: 40px;
      }
    }
  }
}
/*  >  768 */
